import React, { Node, memo } from "react"
import { Link } from "gatsby"
import UpdateLabel from "../UpdateLabel"

type Props = {|
  title: string,
  children: Node,
  updateLink?: {
    label: string,
    url: string,
  },
  classNames?: {
    wrapper: string,
  },
  externalLink?: string | null,
|}

const InformationWrapper = ({
  title,
  children,
  updateLink = {},
  classNames = {
    wrapper: "",
  },

  externalLink,
}: Props) => (
  <div className={`w-full${classNames.wrapper}`}>
    <div className="flex justify-between items-baseline">
      <h3 className="text-left font-base-black text-3xl leading-none text-gray-800">
        {title}
      </h3>
      {Object.keys(updateLink).length > 0 && (
        <>
          {externalLink ? (
            <a
              className="text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800"
              href={externalLink}
            >
              {updateLink.label}
            </a>
          ) : (
            <Link
              className="text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800"
              to={updateLink.url}
            >
              <UpdateLabel label={updateLink.label} />
            </Link>
          )}
        </>
      )}
    </div>
    {children}
  </div>
)

export default memo(InformationWrapper)
